.FactoryListElement {
  background-color: var(--ui-bg-primary);
  padding: 10px;
  color: white;
  display: grid;
  grid-template-columns: [name-start] 300px [name-end template-start] 150px [template-end buttons-start] 300px [buttons-end];
  justify-content: center;

  &:nth-of-type(2n) {
    background-color: rgba(255, 255, 255, 0.05);
  }

  &:nth-of-type(2n+1) {
    background-color: rgba(255, 255, 255, 0.025);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &-name {
    padding: 10px;
    font-weight: bolder;
  }

  &-template {
    padding: 10px;
    color: bisque;
  }
}