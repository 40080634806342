.CardsBrowser {
  height: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(430px, 1fr));
  gap: 2px 2px;
  justify-items: center;
  align-items: stretch;
  align-content: start;
  justify-content: start;
}