.FactorySidePanel {
  background-color: var(--ui-bg-primary);
  color: var(--ui-font-primary);
  border-left: solid 1px black;

  &-section {
    border-top: 1px solid rgba(255,255,255,0.1);

    &-content {
      padding-top: 3px;
      padding-bottom: 3px;
    }

    &-title{
      font-weight: bold;
      padding-top: 3px;
      padding-bottom: 3px;
      background-color: rgba(255,255,255,0.05);
    }
  }
}