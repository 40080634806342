.WelcomeMessage {
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  padding: 10px;

  a {
    font-weight: bold;
    color: lightgoldenrodyellow;
  }
  
  .Support {
    display: none;
    width: 600px;
    text-align: left;
    background-color: rgba(184, 134, 11, 0.2);
    border:rgba(184, 134, 11, 0.5) 2px dashed;
    $radius: 10px;
    border-radius: $radius;
    margin: 10px;

    .Title {
      text-align: center;
      font-weight: bold;
      background-color: rgba(184, 134, 11, 0.1);
      padding: 10px;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
    }

    .Content {
      padding: 10px;
    }
  }
}