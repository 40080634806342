.Card.Item {
  color: white;
  border: 1px solid rgba(255,255,255,0.3);
  cursor: pointer;
  margin: 1px;
  box-sizing: border-box;
  height: 218px;
}

.Card.Item:hover {
  border: 1px solid rgba(255,255,255,0.7);
  background: rgba(255,255,255,0.05)
}

.Card.Item.selected {
  border: 1px solid white;
  background: rgba(255,255,255,0.2)
}