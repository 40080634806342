.FactoryPlanner {
  display: grid;
  grid-template-rows: [space-start] 100% [space-end];
  overflow-y: visible;
  overflow-y:visible;
  height: calc(100% - 101px);

  &-navbar {
    display: grid;
    grid-template-rows: [name-start] 70px [name-end buttons-start] 30px [buttons-end];
    justify-content: left;
    color: white;

    &-status {
      padding-left: 10px;
      text-align: left;
    }

    &-name {
      padding-left: 10px;
      text-align: left;
      font-size: 25px;
    }

    &-overview {
      display: grid;
      grid-template-rows: [name-start] 30px [name-end status-start] 30px [status-end]
    }
  }
}

.FactoryName-input {
  padding-left: 6px;
  margin: 2px;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 25px;
  outline: none;
  background-color: var(--ui-bg-primary);
  color: white;
  border: 2px lightgray solid;
  border-radius: 4px;
}

.FactoryName {
  width: fit-content;
}

.FactoryName-hidden {
  display: none;
}