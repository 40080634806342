.Recipe.Card {
  padding: 0px;
  width:fit-content;
  height: 250px;

  border: solid 1px rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.05);

  display: grid;
  grid-template-rows: 35px auto;
  color: white;

  &:hover{
    background-color: rgba(255, 255, 255, 0.1);
  }

  & > .Title {
    font-weight: bold;
    margin-bottom: 3px;
    padding-bottom: 3px;
    padding-top: 3px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  & > .Content {
    display: grid;
    grid-template-columns: [ls] 120px [in-end] 5px [ex-start]  150px [ex-end] 5px [pr-start] 120px [le];
    padding: 8px;
    width: 100%;

    & > .Ingredients {
      grid-column-start: ls;
      grid-column-end: in-end;
      align-items: center;
      display: grid;
      row-gap: 2px;
      align-content: center;
    }

    & > .Executor {
      grid-column-start: ex-start;
      grid-column-end: ex-end;
      align-self: center;
      border: solid 1px rgba(255, 255, 255, 0.5);
      border-radius: 10px;
    }

    & > .Products {
      grid-column-start: pr-start;
      grid-column-end: le;
      align-items: center;
      display: grid;
      row-gap: 2px;
      align-content: center;
    }
  }
}

.Recipe {
  & > .Title > .Alternative {
    border: rgba(255,255,150,1) solid 1px;
    border-radius: 4px;
    font-size: small;
    padding-left: 2px;
    padding-right: 2px;
    color: rgba(255,255,150,1);
  }

  & > .Title > .Event {
    border: rgba(255,150,150,1) solid 1px;
    border-radius: 4px;
    font-size: small;
    padding-left: 2px;
    padding-right: 2px;
    color: rgba(255,150,150,1);
  }
}

.Executor-img {
  width: 100%;
}

.Item.Amount {
  display: grid;
  grid-template-columns: [ls] 70% [lc] 30% [le];
  align-items: center;
  padding: 4px;

  border: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;  

  & > .Amount {
    grid-column-start: ls;
    grid-column-end: lc;
    font-weight: bold;
  }

  & > .Item {
    grid-column-start: lc;
    grid-column-end: le;
  }

  &-img {
    width: 100%;
  }
}