.SatisfactoryTierDraw {
  padding: 4px;
  border-bottom: 2px solid rgba(255,255,255,0.1);
  padding-bottom: 8px;

  &-title {
    font-size: large;
    font-weight: 600;
    padding: 4px;
  }

  &-milestones {
    display: grid;
    grid-template-columns: [ls] repeat( auto-fit, 150px) [le];
    justify-content: center;
    gap: 4px;
  }
}