.FactoryElement-conn-capsule {
  fill: rgb(70, 70, 70);
}

.FactoryElement-conn {
  outline: none !important;
}

.FactoryElement-conn:hover > .FactoryElement-conn-capsule {
  fill: rgb(83, 83, 83);
}

.FactoryElement-conn-capsule.Status-nok {
  stroke: red;
  stroke-width: 1px;
}

.FactoryElement-conn-capsule.Status-ok {
  stroke: greenyellow;
  stroke-width: 1px;
}

.FactoryElement-io-circle{
  fill: white;
}

.FactoryElement-io-circle.Status-nok {
  fill: red;
}

.FactoryElement-io-circle.Status-ok {
  fill: greenyellow;
}

.FactoryElement-io-hitbox {
  outline: none !important;
}