.App {
  text-align: center;
  height: 100vh;
  overflow-y: hidden;
  background-color: var(--ui-bg-primary);
  color: white;
}

.SinglePage {
  height: 100%;
}

a {
  text-decoration: none;
  color:inherit;
}