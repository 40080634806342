.Button {
  background-color: var(--ui-bg-primary);
  filter: brightness(80%);
  margin: 4px;
  padding: 6px;
  border-radius: 6px;
  color: white;
  border: none;
  text-align: center;

  &-content {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
  }

  &-small {
    background-color: var(--ui-bg-primary);
    filter: brightness(80%);
    margin: 3px;
    padding: 4px;
    border-radius: 6px;
    color: white;
    border: none;
  }

  &:hover:enabled, &-small:hover:enabled {
    filter: brightness(110%);
    cursor: pointer;
  }

  &.Delete, &-small.Delete {
    background-color: brown;
  }

  &.Create, &-small.Create {
    background-color: forestgreen;
  }

  &-text {
    padding-left:2px;
    padding-right:2px;
  }

  &:disabled {
    background-color: dimgray;
    color: lightgray;
  }

  &.Development {
    background-color:darkgoldenrod;
  }
}