.Background-block {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Prompt {
  min-width: 200px;
  border-radius: 10px;
  background-color: var(--ui-bg-primary);

  &-title {
    background-color: rgba(0,0,0,0.2);
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 600;
  }

  &-body {
    padding: 5px;
    
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &-content {
    padding: 5px;
    min-height: 50px;
  }
}