.NavBarMenuItem {
  color: white;
  text-decoration-style: none;
  font-size: large;
  display: inline-block;
  text-align: center;
  position: relative;
  width: fit-content;
  text-align: left;
}

.NavBarMenuItem-name {
  background-color: var(--ui-bg-primary);
  width: 100%;
  height: 100%;
  padding-top: 2px;
  padding-left:10px;
  padding-right:10px;
  height: 30px;
  box-sizing: border-box;
}

.NavBarMenuItem:hover {
  filter: brightness(110%);
}

.NavBarMenuItem-children {
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  display: none;
  position: absolute;
  top: 30px;
  left: 0px;
  width: max-content;

  .NavBarMenuItem {
    display: block;
    width: 100%;
  }
}

.NavBarMenuItem:hover {
  .NavBarMenuItem-children {
    display:block;
  }
}