.Connection-link {
  stroke: white;
}

.liquid.Connection .LinkPath {
  stroke: lightblue;
}

.solid.Connection .LinkPath {
  stroke: rgb(255, 255, 200);
}

.liquid.Connection .TextInRect-rect {
  fill: lightblue;
}

.solid.Connection .TextInRect-rect {
  fill: rgb(255, 255, 200);
}

.Connection .TextInRect {
  font-size: small;
  cursor: pointer;
  outline: none;
}