.NavBar {
  &-content {
    height: 100px;
    background-color: var(--ui-bg-primary);
    color: var(--ui-font-primary);
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    justify-content: center;
    border-bottom: 1px black solid;
  }

  &-placeholder {
    height: 101px;
  }

  &-brand {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100px;
    height: 100px;
    border-right: solid gray 1px;
    align-content: center;
    font-size: small;
    font-weight: 600;
  }

  &-UserPanel {
    position: absolute;
    right: 0px;
    top: 0px;
    border-left: solid gray 1px;
    height: 80px;
    padding: 10px;
  }

  &-logo {
    width: 70px;
    height: 70px;
    margin: 3px;
  }
}