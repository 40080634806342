.Card {
  border: solid 6px rgba(0,0,0,0.6);
  width: 400px;
  margin: 4px;
  padding: 8px;
  color: black;
  border-radius: 14px;

  &-icon {
    max-width: 100%;
    max-height: 100%;
    grid-column-start: ls;
    grid-column-end: lc;
  }

  &-img {
    width: 100%;
    height: 100%;
  }

  &-description {
    grid-column-start: lc;
    grid-column-end: le;
  }

  &-twosides {
    display: grid;
    grid-template-columns: [ls] 1fr [lc] 1fr [le];
  }

  &-name {
    font-weight: bold;
  }
}

.Card.draggable {
  cursor: grab;
}