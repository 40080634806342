.ContextMenu {
  position: absolute;
  padding: 0px;
  background-color: var(--ui-bg-primary);
  min-width: 100px;
  border: 1px solid rgba(0,0,0,0.2);
}

.ContextMenu ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.ContextMenu li {
  width: 100%;
  padding: 4px;
  cursor: pointer;
  list-style-type: none;
  margin: 0px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.ContextMenu li:hover {
  background-color: rgba(0,0,0,0.1);
}