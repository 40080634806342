.FactoryElement {
  fill: rgb(70, 70, 70);
  stroke-width: 1;
}

.FactoryElement-title {
  fill: rgb(83, 83, 83);
}

.FactoryElement-title-text {
  fill: white;
  font: 16px;
  pointer-events: none;
}

.FactoryElement-border {
  fill: black;
}

.FactoryElement-number-rect {
  fill:gray;
  cursor: pointer;
  outline: none;
}

.FactoryElement-number-text {
  fill: white;
  font-size: x-small;
  text-anchor: middle;
  pointer-events: none;
}

.FactoryElement-efficiency-rect {
  fill:gray;
  outline: none;
}

.FactoryElement-efficiency-text {
  fill: white;
  font-size: x-small;
  text-anchor: middle;
  pointer-events: none;
}

.FactoryElement-efficiency-text.Efficiency-very-high {
  fill: greenyellow;
  font-weight: bold;
}

.FactoryElement-efficiency-text.Efficiency-high {
  fill: yellowgreen;
}

.FactoryElement-efficiency-text.Efficiency-medium {
  fill: yellow;
}

.FactoryElement-efficiency-text.Efficiency-low {
  fill: orange;
}

.FactoryElement-efficiency-text.Efficiency-very-low {
  fill: salmon;
  font-weight: bold;
}

.FactoryElement-number-input {
  font-size: x-small;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: none;
  height: 15px;
  width: 30px;
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
  outline:none;
  border-radius: 3px;
}