.MultiSelectInput {
  width: fit-content;
  position: relative;
  display: inline-block;
  min-width: 80px;

  &-selected {
    height: 20px;
    border: gray 1px solid;
    margin: 2px;
    border-radius: 4px;
    padding: 4px;

    &.MultiSelectInput-filtering {
      border: lightgoldenrodyellow 1px solid;
      color: lightgoldenrodyellow;
    }
  }

  &-item {
    cursor: pointer;
    min-width: 150px;
    display: grid;
    grid-template-columns: 20px auto;
    justify-items: left;
    border: 1px solid rgba(255,255,255,0.2);
    padding: 2px;

    &-selected {
      border: lightgoldenrodyellow 1px solid;
      color: lightgoldenrodyellow;
      background-color: rgba(255,255,255,0.1);
    }

    &-not-selected {
      -webkit-flex-order: 1;
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1;
    }
  }

  &-items {
    z-index: 1;
    position: absolute;
    margin-left: 2px;
    background-color: var(--ui-bg-primary);
    display: none;
    border-bottom: gray 1px solid;
    border-left: gray 1px solid;
    border-right: gray 1px solid;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-width: 200px;
    padding: 2px;

    &-list {
      display: grid;
      grid-template-columns: repeat( 3, minmax(200px, 1fr));
      max-height: 400px;
      overflow-y: scroll;
      gap: 2px;
    }

    &-show{
      display: block;
    }

    &-input {
      outline: none;
      border-radius: 15px;
      padding: 4px;
      padding-left: 14px;
      margin-bottom: 5px;
      padding-right: 25px;

      &-clear {
        position: relative;
        top: 6px;
        left: -26px;
        cursor: pointer;
      }
    }
  }

  &:hover > .MultiSelectInput-items {
    display: block;
  }

  &:hover > .MultiSelectInput-selected {
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}