.SingleBuildingPage {
  display: grid;
  grid-template-rows: auto auto;
  overflow: none;

  &-data {
    position: sticky;
    background-color: var(--ui-bg-primary);
    top: 0px;
    display: grid;
    grid-template-columns: [name-start] auto [name-end value-start] auto [value-end];
    padding: 8px;
    column-gap: 16px;
    justify-content: center;
  }

  &-image {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 8px;
  }

  &-table {
    min-width: 500px;
  }

  &-recipes {
    flex-grow: 1;
  }
}