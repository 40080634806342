.FactoryCanvas {
  width: 100%;
  height: 100%;
  background-color: gray;
  overflow-y: hidden;
  will-change: contents;
}

.FactoryCanvas-tutorial {
  position: absolute;
  width: 300px;
  height: 100px;
  top: calc(25% - 50px);
  left: calc(50% - 150px);
  opacity: 0.8;
  pointer-events: none;
}


.AddElementContextMenuDisplay-info {
  width: max-content;
  height: fit-content;

  margin-left: 10px;

  div {
    display: inline-block;
  }
}

.AddElementContextMenuDisplay-items {
  background-color: rgba(255,255,255,0.075);
  border-radius: 6px;
  padding-left: 4px;
  padding-top: 2px;
  margin-left: 6px;
}