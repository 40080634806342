.NewFactoryPage {
  display: grid;
  grid-template-columns: [st] 300px 300px [en];
  justify-content: center;
  justify-items: center;
}

.WholeWidth {
  grid-column-start: st;
  grid-column-end: en;
}