.WelcomePage-header-buttons {
  position:absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.WelcomePage-development {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 8px;
  background-color:rgba(184, 134, 11, 0.2);
  border-top:rgba(184, 134, 11, 0.5) 2px dashed;
}