.PropRow {
  &-name {
    grid-column-start: name-start;
    grid-column-end: name-end;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 3px;
  }

  &-value {
    grid-column-start: value-start;
    grid-column-end: value-end;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 3px;
  }
}