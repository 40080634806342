.EditorCard {
  flex-grow: 1;
}

.EditorSection.horizontal > .EditorCard {
  width: var(--size);
  min-width: var(--min-size);
  max-width: var(--max-size);
  overflow-y: auto;
  height: 100%;
}

.EditorSection.vertical > .EditorCard {
  height: var(--size);
  min-height: var(--min-size);
  max-height: var(--max-size);
}

.EditorCardsSection.cards > .EditorCard {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
}