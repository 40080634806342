.AssetBrowser {
  height: 100%;
  background-color: var(--ui-bg-primary);
  color: var(--ui-font-primary);

  &-window {
    overflow-y: scroll;
    height: 100%;
  }

  &-filterBar {
    padding: 4px;
    border-top: 2px black solid;
    border-bottom: 1px black solid;
    height: fit-content;
    text-align: left;

    &-filter {
      display: inline-block;
      border: 1px solid gray;
      border-radius: 4px;
      padding: 4px;
      margin: 1px;
      cursor: pointer;
    }
  }

  &-assets {
    height: calc(100% - 41px);
  }
}