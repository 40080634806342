.EditorCard-pre:hover {
  background-color: brown;
}

.EditorCard-pre.Resizing {
  background-color: red;
}

.EditorSection.horizontal > .EditorCard-pre {
  min-width: 4px;
  width: 4px;
  cursor: col-resize;
}

.EditorSection.vertical > .EditorCard-pre {
  min-height: 4px;
  height: 4px;
  cursor: row-resize;
}