.SvgCanvas-div {
  width: 100%;
  height: 100%;
}

.SvgCanvas-svg {
  width: 100%;
  height: 100%;
}

.SvgCanvas-background {
  fill: gray;
}