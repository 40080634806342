.FactoryEditor {
  display: grid;
  grid-template-rows: [cavnas-start] 50% [canvas-end assets-start] 50% [assets-end];
  height: 100%;

  &-canvas {
    height: 100%;
    display: grid;
    grid-template-columns: auto 300px;
  }

  &-assets {
    height: 100%;
  }
}