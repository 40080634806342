.EditorSection {
  display: flex;
  flex-wrap: nowrap;
}

.EditorSection.horizontal {
  flex-direction: row;
  align-items: stretch;
}

.EditorSection.vertical {
  flex-direction: column;
}

.EditorSection {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}

.EditorSection-cards {
  border-bottom: 1px solid black;
  padding-top: 4px;
  background: linear-gradient(0deg, rgb(68, 68, 68) 0%, rgb(114, 114, 114) 25%);
  text-align: left;
}

.EditorSection-card {
  display: inline;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  background-color:rgb(39, 39, 39);
  color: rgb(156, 156, 156);
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-left: 2px;
  cursor: pointer;
}

.EditorSection-card.current {
  background: var(--ui-bg-primary);
  color: white;
  border-bottom: 1px solid var(--ui-bg-primary);
}

.EditorCardsSection {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;

  &-content {
    height: 100%;
    max-height: 100%;
  }

}