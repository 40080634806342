.Socket-capsule {
  fill: rgb(70, 70, 70);
}

.Socket {
  outline: none !important;
}

.Socket:hover > .Socket-capsule {
  fill: rgb(83, 83, 83);
}

.Status-nok .Socket-capsule {
  stroke: red;
  stroke-width: 1px;
}

.Status-ok .Socket-capsule {
  stroke: greenyellow;
  stroke-width: 1px;
}

.Status-hidden .Socket-capsule {
  display: none;
}

.Socket-io-circle{
  fill: white;
}

.Status-nok .Socket-io-circle{
  fill: red;
}

.Status-ok .Socket-io-circle {
  fill: greenyellow;
}

.Socket-hitbox {
  outline: none !important;
}