.SatisfactoryMilestoneDraw-title {
  width: 150px;
  height: 100%;
  min-height: 80px;
  padding: 6px;
  border: 1px solid gray;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  font-weight: 600;
}

.SatisfactoryMilestoneDraw-title:hover + .SatisfactoryMilestoneDraw-data {
  display: block;
}

.SatisfactoryMilestoneDraw-title:hover {
  border: 1px solid white;
  background-color: rgba(255,255,255,0.025);
}

.SatisfactoryMilestoneDraw-title.selected {
  border: 2px solid white;
  background-color: rgba(255,255,255,0.1);
}

