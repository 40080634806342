.ConnectionAddingHelper.LinkPath {
  stroke: white;
  opacity: 0.4;
  pointer-events: none;
}

.ConnectionAddingHelper.LinkPath.Status-nok {
  stroke: red;
  opacity: 1;
}

.ConnectionAddingHelper.LinkPath.Status-ok {
  stroke: greenyellow;
  opacity: 1;
}

.ConnectionAddingHelper.LinkPath.Status-hidden {
  opacity: 0;
}

.ConnectionAddingHelper {
  pointer-events:none;
}

.ConnectionAddingHelper.Status-hidden {
  display: none;
}

